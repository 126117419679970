<template>
    <div class="pad-3">
      <div class="bb-container bb-container--lista">
        <h1 class="header-1">{{ $t("piggybankterms.title", lang) }}</h1>
        <div class="post-entry">
          <p v-html="$t('piggybankterms.intro1', lang)"></p>
          <p v-html="$t('piggybankterms.intro2', lang)"></p>
            <p v-html="$t('piggybankterms.clause1', lang)"></p>
            <p v-html="$t('piggybankterms.clause12', lang)"></p>
            <p v-html="$t('piggybankterms.clause2', lang)"></p>
            <p v-html="$t('piggybankterms.clause3', lang)"></p>
            <p v-html="$t('piggybankterms.clause4', lang)"></p>
            <p v-html="$t('piggybankterms.clause5', lang)"></p>
            <p v-html="$t('piggybankterms.clause6', lang)"></p>
            <p v-html="$t('piggybankterms.clause7', lang)"></p>
            <p v-html="$t('piggybankterms.clause8', lang)"></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PbTerms",
    metaInfo() {
      return {
        title: this.$t('piggybankterms.metatitle'),
        meta: [
          { name: "description", content: this.$t("piggybankterms.metadescription") },
          { name: "robots", content: "noindex"}
        ],
      };
    },
    computed: {
      lang() {
        return this.$route.meta?.lang || this.$i18n.locale;
      }
    },
  };
  </script>
  