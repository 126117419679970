<template>
    <div class="pad-3">
      <div class="bb-container bb-container--lista">
        <h1 class="header-1">{{ $t("contributorterms.title", lang) }}</h1>
        <div class="post-entry">
            <p v-html="$t('contributorterms.clause1', lang)"></p>
            <p v-html="$t('contributorterms.clause2', lang)"></p>
            <p v-html="$t('contributorterms.clause3', lang)"></p>
            <p v-html="$t('contributorterms.clause4', lang)"></p>
            <p v-html="$t('contributorterms.clause5', lang)"></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PbContributorTerms",
    metaInfo() {
      return {
        title: this.$t('piggybankterms.metatitle'),
        meta: [
          { name: "description", content: this.$t("piggybankterms.metadescription") },
          { name: "robots", content: "noindex"}
        ],
      };
    },
    computed: {
      lang() {
        return this.$route.meta?.lang || this.$i18n.locale;
      }
    },
  };
  </script>